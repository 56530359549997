<template>
  <div>
    <div class="user-posting">
        <b-row>
          <b-col sm="12"><h5>Promote Your Online classes</h5></b-col>
        </b-row>    
        <b-row>
          <b-col sm="8 mt-2"><p>Publish your online class details and promote it through thiraseela.com</p></b-col>
          <b-col sm="4 mt-2"><b-button pill type="submit" variant="warning"><router-link :to="{name:'online-classes',params:{ Maincatg:'All'}}">View all online classes</router-link></b-button></b-col>
        </b-row>
    </div>    
    <b-col cols="12 my-4">
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                <b-form-group id="input-PrgName" label-for="PrgName" class="col-8">
                  <b-form-input
                    id="PrgName"
                    v-model="InpForm.PrgName"
                    placeholder="Promotion Title"
                    :maxlength="MaxChar"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form class="col-8">
                <b-form-group id="input-ShowType" label-for="ShowType" >
                    <b-form-select 
                      v-model="InpForm.PromoType" 
                      :options="ListPromoType">
                    </b-form-select>
                </b-form-group>
                </b-form>                

                <RefFormShowBy @EmitShowBy="SetShowBy" @EmitShowType="SetShowType" :key="ComponentKey +1"/>

                <!-- <b-form-group id="input-ArtName" label-for="ArtName" class="col-8">
                  <b-form-input
                    id="ArtName"
                    v-model="InpForm.ArtName"
                    placeholder="Troupe/Artist Name"
                    required
                  ></b-form-input>
                </b-form-group> -->

                <!-- <b-form-group class="col-8">
                  <b-form-textarea
                    id="textarea"
                    size="md"
                    v-model="InpForm.ArtDesc"
                    placeholder="Troupe/Artist Description"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>  
                </b-form-group> -->

                <b-form-group class="col-8">
                  <b-form-textarea
                    id="textarea"
                    size="md"
                    v-model="InpForm.PrgDesc"
                    placeholder="Promotion Description"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>  
                </b-form-group>                            
                
                <!-- <b-form-group id="input-PrgType" label-for="PrgType" class="col-8">
                  <b-form-select 
                    v-model="InpForm.PrgType" 
                    :options="ListPrgType">
                  </b-form-select>
                </b-form-group> -->

                <RefFormCatg :ShowMainCatg="true" :ShowMultpleCatg="false" @EmitMainCatg="SetMainCatg" :key="ComponentKey +2"/>

                <b-form-group class="col-8">
                  <b-form-textarea
                    id="textarea"
                    size="md"
                    v-model="InpForm.Address"
                    placeholder="Contact Address"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>  
                </b-form-group>

                <RefFormPlaces :ShowCountry="true" @EmitCountry="SetCountry" @EmitState="SetState" @EmitCity="SetCity" :key="ComponentKey"/>
                <!-- <b-form inline class="col-12">
                <b-form-group id="input-group-2" label-for="country">
                  <b-form-input
                    id="country"
                    v-model="InpForm.country"
                    placeholder="Country"
                    required
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="state" >
                  <b-form-input
                    id="state"
                    v-model="InpForm.state"
                    placeholder="State"
                    required
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="city">
                  <b-form-input
                    id="city"
                    v-model="InpForm.city"
                    placeholder=" City"
                    required
                  ></b-form-input>                
                </b-form-group>
              </b-form> -->

              <b-form inline class="col-12 mt-2">
                <b-form-group id="input-group-2" label-for="cntct1">
                  <b-form-input
                    id="cntct1"
                    v-model="InpForm.cntct1"
                    placeholder="Contact no #1"
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="cntct2">
                  <b-form-input
                    id="cntct2"
                    v-model="InpForm.cntct2"
                    placeholder="Contact no #2"
                  ></b-form-input>                
                </b-form-group>
              </b-form>

              <b-form inline class="col-12 mt-2">
                <b-form-group id="input-group-2" label-for="email" >
                  <b-form-input
                    id="email"
                    v-model="InpForm.email"
                    @change="FindNewUserEmailID()"
                    placeholder="Your Email ID"
                  ></b-form-input>                
                </b-form-group>   

                <b-form-group id="input-group-2" label-for="website">
                  <b-form-input
                    id="website"
                    v-model="InpForm.website"
                    placeholder="Your Website ID"
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="facebook">
                  <b-form-input
                    id="facebook"
                    v-model="InpForm.facebook"
                    placeholder="Your Facebook ID"
                  ></b-form-input>                
                </b-form-group>             
              </b-form>

             <b-form inline class="col-12 mt-2">
                <b-form-group id="input-group-2" label-for="instagram">
                  <b-form-input
                    id="instagram"
                    v-model="InpForm.instagram"
                    placeholder=" Your Instagram ID"
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-2" label-for="youtube">
                  <b-form-input
                    id="youtube"
                    v-model="InpForm.youtube"
                    placeholder="Your Youtube ID"
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="twitter">
                  <b-form-input
                    id="twitter"
                    v-model="InpForm.twitter"
                    placeholder="Your Twitter ID"
                  ></b-form-input>                
                </b-form-group>                
              </b-form>                                      
<!-- 
                <b-row class="my-3">                  
                  <b-col cols="12">         
                    <b-button class="float-left ml-3" variant="success"  @click="click1"> {{ButtonText}}
                    </b-button>
                    <input type="file" ref="input1"
                      style="display: none"
                      @change="previewImage" accept="image/*" >                                        
                  </b-col>                  
                </b-row> -->

                <b-form-group class="col-8 mt-2">
                    <b-form-file
                      v-model="file1"
                      :state="Boolean(file1)"
                      placeholder="Choose a cover picture..."
                      drop-placeholder="or Drop file here..."
                      @change="previewImage" accept="image/*" 
                    ></b-form-file>
                </b-form-group>
             

                <!-- <b-row>
                  <b-col cols="12 my-3 ml-3" >                    
                    <b-img v-if="ShowPhoto" class="float-left" rounded height="80" width="80" :src="PhotoPreview" >                 
                    </b-img>
                      <b-spinner v-else-if="ShowSpinner" small
                        v-for="variant in variants"
                        :variant="variant"
                        :key="variant"
                      ></b-spinner>                
                  </b-col>  
                </b-row> -->
           
                <div cols="12" class="my-3 ml-3">
                  <b-button pill type="submit" variant="primary">Submit</b-button>
                  <b-button pill type="reset" variant="danger">Cancel</b-button>
                </div>
      </b-form>        
    </b-col>
    <b-modal v-model="modalShow" @ok="SendLoginLink"><b-alert variant="success" show>Your email id {{RespEmailID}} is already linked with a profile. Click OK to receive a login link.</b-alert></b-modal>
    <b-modal v-model="ModalRegDone" @ok="NextRouteLogin"><b-alert variant="success" show>Success! Check your Email and follow the instructions.</b-alert></b-modal>         
  </div>
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'
import {fb} from '../firebase'
import { mapGetters } from "vuex";

Vue.use(Vueaxios,axios)
  export default {
    data() {
      return {
      // Input form variables
      InpForm: {
        PrgName: '',
        PromoType: 'default',
        ArtName: '',
        ArtDesc: '',
        PrgDesc: '',
        PrgCatg: 'default',
        // PrgType: 'default',
        Address: '',
        cntct1: '',
        cntct2: '',
      email: '',
      website: '',
      facebook: '',
      instagram: '',
      youtube: '',
      twitter: '',
      },
      ComponentKey: 0,
      ChildMainCatg: '',
      ChildShowBy: '',
      ChildShowType: '',
      ChildCountry: '',
      ChildState: '',
      ChildCity: '',
      MaxChar: 100,
        RespUserEmail: null,
        RespEmailID: null,
        RespName: null,
        modalShow: false,   
        ModalRegDone: false,       
      // Firebase image upload variables
      PhotoPreview: null,
      PhotoUrl: 'https://storage.googleapis.com/thiramedia/promotions/',
      imageData: null,
      file1: null,
      // Response array variables
      RespIns: [],
      RespGetMaxID: [ {tbl_next_seq: '0'} ],
      // Select drop down options
      ListPromoType: [
        { value: 'default', text: 'Select Promotion Type' },
        { value: 'Online Training', text: 'Online Training' },
        { value: 'Magazines', text: 'Magazines' },
        { value: 'Books', text: 'Books' },
      ],          
      // Other variables
      show: true,
      NextSeqNo: '',
      // ShowSpinner: false,
      // ShowPhoto: false,
      variants: ['primary', 'danger', 'warning'],
      // ButtonText: 'Upload cover photo',
      }
    },
    mounted()
    {
    },    
    methods: {
      onSubmit(event) {
        event.preventDefault()
        this.GetMaxID()
      },

      SetMainCatg(event) {
          this.ChildMainCatg = event
          // console.log('ChildMainCatg',this.ChildMainCatg)
      },

      SetShowBy(event) {
          this.ChildShowBy = event
          // console.log('ChildShowBy',this.ChildShowBy)
      },
      
      SetShowType(event) {
          this.ChildShowType = event
          // console.log('ChildShowType',this.ChildShowType)
      },        
      
      SetCountry(event) {
          this.ChildCountry = event
          console.log('ChildCountry',this.ChildCountry)
      },
      SetState(event) {
          this.ChildState = event
          console.log('ChildState',this.ChildState)
      },
      SetCity(event) {
          this.ChildCity = event
          console.log('ChildCity',this.ChildCity)
      },        

      GetMaxID(){
        const REQformData = new FormData();
        REQformData.append('table_name', 'promotion_main');
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=GetMaxID',REQformData)
        .then((resp)=>{
        this.RespGetMaxID=resp.data.GetMaxID;
        const FirstObject = this.RespGetMaxID[0];
        this.NextSeqNo = FirstObject.tbl_next_seq;
        // console.log('MaxSeqNo',this.NextSeqNo)
        this.InsPromotions()
        this.onUpload()
      })
      },      
          
      InsPromotions(){
        this.ComponentKey=0;
        const REQformData = new FormData();
        REQformData.append('seq_no', this.NextSeqNo);
        REQformData.append('promo_title', this.InpForm.PrgName);
        REQformData.append('promo_type', this.InpForm.PromoType);
        REQformData.append('owner_type', this.ChildShowType);
        REQformData.append('owner_id', this.ChildShowBy);
        REQformData.append('catg_main', this.ChildMainCatg);
        REQformData.append('description', this.InpForm.PrgDesc);
        REQformData.append('address', this.InpForm.Address);
        REQformData.append('prof_city', this.ChildCity);
        REQformData.append('prof_state', this.ChildState);
        REQformData.append('prof_country', this.ChildCountry);
        REQformData.append('contact1', this.InpForm.cntct1);
        REQformData.append('contact2', this.InpForm.cntct2);
        REQformData.append('email', this.InpForm.email);
        REQformData.append('website', this.InpForm.website);
        REQformData.append('facebook', this.InpForm.facebook); 
        REQformData.append('instagram', this.InpForm.instagram); 
        REQformData.append('youtube', this.InpForm.youtube);
        REQformData.append('twitter', this.InpForm.twitter);
        REQformData.append('logo', this.PhotoUrl + this.NextSeqNo + '/image1.jpg');

        // Logic to assign profile owner
        if (this.user.authenticated) {REQformData.append('created_by', this.user.data.email)}
        else if (this.InpForm.email != '') {REQformData.append('created_by', this.InpForm.email)}
        else {{REQformData.append('created_by', 'info@thiraseela.com')}}

        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=InsPromotions',REQformData)
        .then((resp)=>{
          this.RespIns=resp.data.message
          alert(this.RespIns)
          this.ClearForms()
          })
        .catch(error => {
          alert(error)
          })
      },
       
      ClearForms(){
       if (this.user.authenticated) {this.$router.replace('/user/dashboard')}
        this.InpForm.PrgName='',
        this.InpForm.PromoType='default'
        this.InpForm.ArtName='',
        this.InpForm.ArtDesc='',
        this.InpForm.PrgDesc='',
        this.InpForm.PrgCatg='default',
        // this.InpForm.PrgType='default',
        this.InpForm.Address='',
        this.InpForm.cntct1='',
        this.InpForm.cntct2='',
        this.InpForm.email='',
        this.InpForm.website='',
        this.InpForm.facebook='',
        this.InpForm.instagram='',
        this.InpForm.youtube='',
        this.InpForm.twitter='',                
        this.imageData=null;
        // this.ShowSpinner=false;
        // this.ShowPhoto=false;
        this.ComponentKey=1;
        this.file1=null;
        // this.ButtonText='Upload cover photo';
      },
                 
      onReset(event) {
        event.preventDefault()
        this.ClearForms()
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
        this.show = true
        })
      },

// Firebase image upload methods
      click1() {
        this.$refs.input1.click()   
      },
      
      previewImage(event) {
        // this.ShowSpinner=true;
        this.uploadValue=0;
        this.PhotoPreview=null;
        this.imageData = event.target.files[0];
        // this.ShowSpinner=false;
        // this.ButtonText='Submit to upload...'
      },

      onUpload(){
        this.PhotoPreview=null;
        // const storageRef=fb.storage().ref(`${this.imageData.name}`).put(this.imageData);
        const storageRef=fb.storage().ref().child('promotions/' + this.NextSeqNo +'/' + 'image1.jpg').put(this.imageData);
        storageRef.on(`state_changed`,snapshot=>{
        this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
          }, error=>{console.log(error.message)},
        ()=>{this.uploadValue=100;
            storageRef.snapshot.ref.getDownloadURL().then(()=>{
                // this.ShowSpinner=false;
                // this.PhotoPreview =url;
                // this.ShowPhoto=true;
                // console.log(this.PhotoPreview)
              });
            }      
          );
       },
    SendLoginLink(){
        this.SignInLinkToEmail(this.RespEmailID);
      },

      NextRouteLogin() {
        this.$router.replace('/')
      },      
      
      SignInLinkToEmail(InpUserEmail1) {
      var actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: 'https://thiraseela.com/user/confirm-login',
        // url: 'https://thiraseela.com/user/SetUserPswd', 
        // This must be true.
        handleCodeInApp: true,
      };        
        fb.auth().sendSignInLinkToEmail(InpUserEmail1, actionCodeSettings)
        .then(() => {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem('emailForSignIn', InpUserEmail1);
          this.ModalRegDone=true
        })
        .catch(() => {
          console.log('Error!!')
        });
      }, 
      FindNewUserEmailID() {
        const REQformData = new FormData();
        REQformData.append('email_id', this.InpForm.email);          
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=FindNewUserEmailID',REQformData)
        .then((resp)=>{
        this.RespUserEmail=resp.data.FindNewUserEmailID;
        const FirstObject = resp.data.FindNewUserEmailID[0];
        this.RespEmailID = FirstObject.user_email;
        this.RespName = FirstObject.user_name;
        this.modalShow=true;
      })
      .catch(() => {
          console.log('Email ID not found!!')
        });
      },       
    },
  computed: {
    // map `this.user` to `this.$store.getters.user` 
    ...mapGetters({
      user: "user"
    })
    }        
  }
</script>